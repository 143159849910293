import { analytics, db, isLocal } from './firebase';

// Logs a lead entry for the entry based on the entry key from storage ie: 'event_lead'
export function logLeadEntry(entryKey) {

    if (isLocal) {
        console.log('skipping lead entry logging in local');
        return;
    }
    // console.log('logging lead entry', entryKey);
    analytics.logEvent(entryKey, {
        lead: entryKey,
        time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
    });
}

// Logs a lead entry for the waitlist based on the entry key from storage
export function logWaitlistLead(entryKey) {
    if (isLocal) {
        console.log('skipping waitlist lead logging in local');
        return;
    }
    // console.log('logging waitlist lead', entryKey);
    analytics.logEvent(`waitlist_${entryKey}`, {
        lead: entryKey,
        time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
    });
}

// Logs a lead entry for the waitlist based on the entry key from storage
export function logContactUsLead(entryKey) {
    if (isLocal) {
        console.log('skipping waitlist lead logging in local');
        return;
    }
    // console.log('logging waitlist lead', entryKey);
    analytics.logEvent(`contact_${entryKey}`, {
        lead: entryKey,
        time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
    });
}

export function logBuyLead(entryKey) {
    if (isLocal) {
        console.log('skipping buy lead logging in local');
        return;
    }
    // console.log('logging buy lead', entryKey);
    analytics.logEvent(`buy_${entryKey}`, {
        lead: entryKey,
        time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
    });
}